
/* [src] src/support-app/RechnungsLaufController.js */
(function () {
  "use strict";

  angular.module("MeldecenterApp").controller("RechnungslaufController", function ($scope, ToolsService, _http, Requests, $timeout) {
    $scope.setTitle("Rechnungs-Läufe");
    
    var vm = this;
    
    vm.lauf = "update";
    vm.jahr = "2025";
    vm.stand = "???";
    
    vm.laufGestartet = false;
    vm.laufInfo = null;
    
    vm.starte = function(name){
      vm.laufGestartet = true;
      _http.post("api/intern/crm/rechnung/lauf/" + vm.lauf + "/" + vm.jahr, function(laufInfo){
        vm.laufGestartet = false;    
        vm.laufInfo = laufInfo;
      });
    };
    
    
    // ***** Daten für die Berechnung *****
    vm.datenErzeugen = function(){
      _http.post("api/intern/crm/rechnung/berechnungsdaten");
      ToolsService.showToast("Erzeugung der Berechnungsdaten wurde gestartet", "info");
    };
    
    _http.getPlain("api/intern/crm/rechnung/berechnungsdaten", function(stand){
      vm.stand = stand;
    });
    
    return vm;
  });
})();
